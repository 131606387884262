import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';

import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import { FiMapPin } from 'react-icons/fi';
import './Form.css';
// React tooltip
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
// Components
import Button from "./components/materialComponents/CustomButtons/Button.js";
import CircularProgress from '@mui/material/CircularProgress';
import { mediaUrl } from './utils/constants';

import { BusinessLocationModal } from './components/BusinessLocationModal';

import * as openPulseData from './assets/animations/openPulse.json';
import * as closedPulseData from './assets/animations/closedPulse.json';

const WizardFormLandingPage = props => {
  const {
    nextPage,
    business_id,
    business_img,
    booking_enabled,
    business_settings,
    business_location_hours,
    business_location_id,
    timezone_name,
    accountPage,
    socialRedirectLoading
  } = props;
  const [businessLocationModalVisible, setBusinessLocationModalVisible] = useState(false);
  const [selectedBusinessLocationId, setSelectedBusinessLocationId] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [businessLocationHoursObj, setBusinessLocationHoursObj] = useState(null);

  useEffect(() => {
    let isOpenToday = false;
    let hoursLocation, businessLocationHoursObj;
    if(business_location_hours?.length > 0) {
      if(business_location_hours?.length > 1 && (business_location_id || selectedBusinessLocationId)) {
        let selectedLocationId = business_location_id ? business_location_id : selectedBusinessLocationId;
        businessLocationHoursObj = business_location_hours.find(x => x.business_location_id === selectedLocationId);
      } else {
        businessLocationHoursObj = business_location_hours[0];
      }
      hoursLocation = businessLocationHoursObj?.business_location_name;
      if(businessLocationHoursObj?.is_open) {
        const now = dayjs();
        const open = dayjs(businessLocationHoursObj.start_time, 'HH:mm:ss');
        const close = dayjs(businessLocationHoursObj.end_time, 'HH:mm:ss');
        if(now.isSameOrAfter(open, 'minute') && now.isSameOrBefore(close, 'minute')) {
          isOpenToday = true;
        }
      }
      setBusinessLocationHoursObj(businessLocationHoursObj);
    }
    setOpen(isOpenToday);
  }, []);

  const renderLottiePulse = (isOpen) => {
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: isOpen ? openPulseData : closedPulseData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <Lottie
        options={defaultOptions}
        height={50}
        width={50}
        isClickToPauseDisabled={true}
        style={{
          margin: 0,
          position: 'relative',
          top: -10,
          right: -5,
          justifyContent: 'center'
        }}
      />
    );
  }
  const handleHoursClick = () => {
    if(business_location_hours?.length > 1) {
      setBusinessLocationModalVisible(true);
    }
  }
  const renderLandingImg = () => {
    if(business_id > 0) {
      return (
        <div className="landing-img-text">
          <div>{props.business_logo ? <img className="business-logo" src={props.business_logo}/> : props.business_name}</div>
          <div className="overlay-line" style={{borderTopColor: props.business_settings?.landing_body_colour}}/>
          {props.business_account_type && props.business_account_type === 1 ?
            <div>
              <a href={props.business_app_ios_url} target="_blank" id="ios_badge">
                <img alt="ios_badge" src={'/App_Store.svg'} width="120px" style={{marginRight: 10}}/>
              </a>
              <a href={props.business_app_android_url} target="_blank" id="android_badge">
                <img alt="android_badge" src={'/Google_Play_Store.svg'} width="135px" style={{marginLeft: 10}}/>
              </a>
            </div>
          : null}
          <div
            onClick={() => handleHoursClick()}
            style={{ fontSize: '0.65em', marginTop: '0.7em', display: 'flex', justifyContent: 'center', maxHeight: 30, cursor: 'pointer' }}
          >
            {business_location_hours ? (
              <div
                style={{ display: 'flex' }}
                data-tooltip-content={businessLocationHoursObj?.is_open ? businessLocationHoursObj.start_time.substring(0,5) + ' - ' + businessLocationHoursObj.end_time.substring(0,5)  : null}
                data-tooltip-id="location-hours-tooltip"
              >
                {renderLottiePulse(isOpen)}
                <span>We're {businessLocationHoursObj?.is_open ? (isOpen ? "Open" : "Closed") : "Closed Today"}</span>
              </div>
            ) : (
              <CircularProgress color="secondary" size={20}/>
            )}
          </div>
          <Tooltip id="location-hours-tooltip" place="bottom" style={{ fontSize: '0.5em' }}/>
          {/*hoursLocation && business_location_hours?.length > 1 ? (
            <div style={{ fontSize: '0.4em', marginTop: '0.5em' }}>
              <FiMapPin/> {hoursLocation}
            </div>
          ) : null*/}
          <BusinessLocationModal
            visible={businessLocationModalVisible}
            toggleVisible={() => setBusinessLocationModalVisible(!businessLocationModalVisible)}
            handleLocationClick={(e) => {
              setSelectedBusinessLocationId(e.business_location_id);
              setBusinessLocationModalVisible(false);
            }}
            data={business_location_hours}
          />
        </div>
      );
    } else if(business_id === 0) {
      return (
        <div className="landing-img-text">
          Business Not Found
          <div className="overlay-line"/>
          <div style={{ margin: 10, fontSize: 13 }}>Oops! Looks like you've entered an incorrect booking URL.</div>
        </div>
      );
    }
  }
  let backgroundStyle = {backgroundImage: `url(${mediaUrl + business_img})`};
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className="landing-title" style={{backgroundColor: business_settings?.landing_header_background}}>
        <span className="landing-title-container">
          <img className="landing-logo" src={business_settings?.landing_header_logo === 'light' ? '/logo/styler_logo_light.svg' : '/logo/styler_logo_dark.svg'} height="60px"/>
        </span>
      </div>
      <div className="landing-img" style={business_id > 0 ? backgroundStyle : { backgroundColor: 'rgba(0,0,0,0.65)' }}>
        <div
          className="landing-img-overlay"
          style={business_settings?.landing_body_gradient_start && business_settings?.landing_body_gradient_end ? { background: `linear-gradient(160deg,${business_settings?.landing_body_gradient_start},${business_settings?.landing_body_gradient_end})` } : null}
        >
          {renderLandingImg(props)}
        </div>
      </div>
      <div className="landing-footer" style={{backgroundColor: business_settings?.landing_footer_background}}>
        <div className="landing-footer-title">
          <div className="landing-footer-button-group">
            <Button block style={{ marginRight: 5 }} onClick={nextPage} disabled={booking_enabled !== 1 || socialRedirectLoading === true}>New Booking</Button>
            <Button block style={{ marginLeft: 5 }} onClick={accountPage} disabled={!business_id || business_id === 0 || socialRedirectLoading === true}>Account</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default (WizardFormLandingPage);