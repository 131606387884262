import React, { Component } from 'react';
import swal from 'sweetalert';
import {
  FiUser,
  FiChevronRight,
  FiEdit2,
  FiFileText,
  FiMapPin,
  FiLifeBuoy,
  FiLogOut,
  FiBook,
  FiCreditCard,
  FiMail,
  FiPlus,
  FiCheck,
  FiX
} from 'react-icons/fi';
import { PaymentMethodsModal } from './PaymentMethodsModal';
import { BusinessLocationModal } from './BusinessLocationModal';
import { mediaUrl } from './../utils/constants';
import remoteApi from './../api/remoteApi';

class AccountMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: [],
      defaultPaymentMethod: null,
      paymentMethodsModalVisible: false,
      businessLocationModalVisible: false
    };
  }

  handleVerifyEmail() {
    if(this.props.user) {
      if(this.props.user.email_verified === true) {
        swal({
          title: "Verified",
          text: "Your email has already been verified!",
          icon: "success",
          buttons: false,
          timer: 2500,
          timerProgressBar: true
        });
      } else {
        swal({
          title: "Not verified",
          text: "Would you like us to send you a verification email?",
          icon: "warning",
          buttons: {
            confirm: {
              text: "Yes",
              value: true,
              visible: true,
              className: "swal-custom-btn",
              closeModal: true,
            }, cancel: {
              text: "No",
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            }
          },
          dangerMode: true,
        }).then(shouldValidate => {
          if(shouldValidate) {
            try {
              remoteApi.verifyEmail();
            } catch(e) {
              return;
            }
          } else {
            return;
          }
        });
      }
    } else {
      return;
    }
  }

  handleFindUs(e) {
    if(this.props.businessLocationData && this.props.businessLocationData.length === 1) {
      let latitude = this.props.businessLocationData[0].address_latitude;
      let longitude = this.props.businessLocationData[0].address_longitude;
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`, '_blank').focus();
    } else if(this.props.businessLocationData && this.props.businessLocationData.length > 1) {
      this.setState({ businessLocationModalVisible: true });
    } else {
      return;
    }
  }

  handlePaymentMethodsClick() {
    this.getPaymentMethods();
    this.togglePaymentMethodsModal();
  }

  togglePaymentMethodsModal = () => {
    this.setState({ paymentMethodsModalVisible: !this.state.paymentMethodsModalVisible });
  }

  toggleBusinessLocationModal = () => {
    this.setState({ businessLocationModalVisible: !this.state.businessLocationModalVisible });
  }

  handleLocationClick = (e) => {
    let latitude = e.address_latitude;
    let longitude = e.address_longitude;
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`, '_blank').focus();
  }

  async getPaymentMethods() {
    try {
      let customer = await remoteApi.getStripeCustomer();
      const default_source = customer.data.default_source;
      const default_payment_method = customer.data.sources ? customer.data.sources.find(x => x.id === default_source) : null;
      this.setState({
        stripeCustomerId: customer.data.stripe_customer_id,
        defaultPaymentMethod: default_payment_method,
        paymentMethods: customer.data.sources
      });
    } catch(e) {
      console.log('Unable to get payment methods');
    }
  }

  submitPaymentMethodChange = async (paymentMethodObj) => {
    try {
      if(paymentMethodObj.id !== this.state.defaultPaymentMethod.id) {
        this.setState({ paymentMethodsModalVisible: false });
        await remoteApi.setDefaultPaymentMethod({ payment_method_id: paymentMethodObj.id });
        this.setState({ defaultPaymentMethod: paymentMethodObj });
      } else {
        this.setState({ paymentMethodsModalVisible: false });
      }
    } catch(e) {
      this.setState({ paymentMethodsModalVisible: false });
    }
  }

  submitNewPaymentMethod = async (token) =>  {
    try {
      this.setState({ paymentMethodsModalVisible: false });
      await remoteApi.addPaymentMethod({ token });
    } catch(e) {
      this.setState({ paymentMethodsModalVisible: false });
    }
  }

  submitDeletePaymentMethod = async (paymentMethodObj) =>  {
    try {
      this.setState({ paymentMethodsModalVisible: false });
      await remoteApi.deletePaymentMethod({ payment_method_id: paymentMethodObj.id, business_id: this.props.businessId });
    } catch(e) {
      swal({
        title: "Oops",
        text: "We were unable to delete this payment method - at least one of your payment methods is required to be on file.",
        icon: "error",
        buttons: false,
        timer: 2500,
        timerProgressBar: true
      });
      this.setState({ paymentMethodsModalVisible: false });
    }
  }

  render() {
    var backgroundStyle = {
      backgroundImage: `url(${mediaUrl + this.props.business_img})`,
      float: 'unset',
      width: '100%'
    };
    return (
      <div className="account-menu" style={ backgroundStyle }>
        <div className="account-menu-outer-container">
          <div className="user-container-sm">
            <div className="user-avatar-container">
              <div className="user-avatar">
                <FiUser size={50} color={'#ffffff'} />
              </div>
            </div>
            <div className="user-header">
              {this.props.user ? this.props.user.user_firstname + ' ' + this.props.user.user_lastname : null}
            </div>
            <div className="account-menu-container">
              <div>
                <div className="account-menu-section-title" style={{ marginTop: 0 }}>Account</div>
                <div className="account-menu-section-container">
                  <div
                    className="account-menu-section-item"
                    onClick={() => this.props.editAccount()}
                  >
                    <div className="account-menu-section-item-title">
                      <FiEdit2 size={16} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                      Edit Details
                    </div>
                    <div>
                      <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                    </div>
                  </div>
                  {this.props.user ?
                    <div
                      className="account-menu-section-item"
                      onClick={() => this.handleVerifyEmail()}
                    >
                      <div className="account-menu-section-item-title">
                        <FiMail size={16} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                        Email Verified
                      </div>
                      <div>
                        {this.props.user && this.props.user.email_verified === true ? (
                          <FiCheck color={"#4caf50"} size={16} style={{ paddingTop: 3 }}/>
                        ) : (
                          <FiX color={"#f44336"} size={16} style={{ paddingTop: 3 }}/>
                        )}
                      </div>
                    </div>
                  : null}
                  {this.props.businessStripeAccount && this.props.businessStripeAccount === true ?
                    <div className="account-menu-section-item" onClick={() => this.handlePaymentMethodsClick()}>
                      <div className="account-menu-section-item-title">
                        <FiCreditCard size={16} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                        Payment Methods
                      </div>
                      <div>
                        <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                      </div>
                    </div>
                  : null}
                </div>
              </div>
              <div>
                <div className="account-menu-section-title">Bookings</div>
                <div className="account-menu-section-container">
                  <div
                    className="account-menu-section-item"
                    onClick={() => this.props.navigateBookingsScreen()}
                  >
                    <div className="account-menu-section-item-title">
                      <FiBook size={16} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                      My Bookings
                    </div>
                    <div>
                      <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                    </div>
                  </div>
                  {this.props.bookingEnabled === 1 ?
                    <div
                      className="account-menu-section-item"
                      onClick={() => this.props.bookAgain()}
                    >
                      <div className="account-menu-section-item-title">
                        <FiPlus size={16} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                        New Booking
                      </div>
                      <div>
                        <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                      </div>
                    </div>
                  : null}
                </div>
              </div>
              <div>
                <div className="account-menu-section-title">Help</div>
                <div className="account-menu-section-container">
                  {this.props.businessLocationData && this.props.businessLocationData.length > 0 ?
                    <div
                      className="account-menu-section-item"
                      onClick={(e) => this.handleFindUs(e)}
                    >
                      <div className="account-menu-section-item-title">
                        <FiMapPin size={15} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                        Find Us
                      </div>
                      <div>
                        <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                      </div>
                    </div>
                  : null}
                  <div
                    className="account-menu-section-item"
                    onClick={() => window.open('https://help.styler.digital/support/contact_support', '_blank').focus()}
                  >
                    <div className="account-menu-section-item-title">
                      <FiLifeBuoy size={15} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                      Report A Problem
                    </div>
                    <div>
                      <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="account-menu-section-title">Legal</div>
                <div className="account-menu-section-container">
                  <div
                    className="account-menu-section-item"
                    onClick={() => window.open('https://help.styler.digital/legal/privacy_policy', '_blank').focus()}
                  >
                    <div className="account-menu-section-item-title">
                      <FiFileText size={15} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                      Privacy Policy
                    </div>
                    <div>
                      <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                    </div>
                  </div>
                  <div
                    className="account-menu-section-item"
                    onClick={() => window.open('https://help.styler.digital/legal/terms_of_use', '_blank').focus()}
                  >
                    <div className="account-menu-section-item-title">
                      <FiFileText size={15} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                      Terms Of Use
                    </div>
                    <div>
                      <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                    </div>
                  </div>
                  <div
                    className="account-menu-section-item"
                    onClick={() => window.open('https://help.styler.digital/legal/booking_terms', '_blank').focus()}
                  >
                    <div className="account-menu-section-item-title">
                      <FiFileText size={15} style={{ top: 2, marginRight: 10, position: 'relative' }}/>
                      Booking Terms
                    </div>
                    <div>
                      <FiChevronRight size={16} style={{ paddingTop: 3 }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="account-menu-section-container sign-out">
                  <div
                    className="account-menu-section-item"
                    onClick={() => this.props.signOut()}
                  >
                    <div>Sign Out</div>
                    <FiLogOut size={16} style={{ paddingTop: 3 }}/>
                  </div>
                </div>
              </div>
              <div className="account-menu-version">
                Version 1.10.1
              </div>
            </div>
          </div>
        </div>
        <PaymentMethodsModal
          visible={this.state.paymentMethodsModalVisible}
          toggleVisible={this.togglePaymentMethodsModal}
          data={this.state.paymentMethods}
          defaultPaymentMethod={this.state.defaultPaymentMethod}
          elements={this.props.elements}
          stripe={this.props.stripe}
          submitDefaultPaymentMethod={this.submitPaymentMethodChange}
          submitNewPaymentMethod={this.submitNewPaymentMethod}
          submitDeletePaymentMethod={this.submitDeletePaymentMethod}
          allowDelete={true}
        />
        <BusinessLocationModal
          visible={this.state.businessLocationModalVisible}
          toggleVisible={this.toggleBusinessLocationModal}
          handleLocationClick={this.handleLocationClick}
          data={this.props.businessLocationData}
        />
      </div>
    );
  }
}

export default AccountMenu;